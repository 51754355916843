
import FlexibleSectionMixin from '~/mixins/flexible-section';

export default {
    mixins: [
        FlexibleSectionMixin,
    ],

    props: {
        collectionEntry: {
            type: Array,
            default: null
        }
    },
};
